import React from 'react';
import NextHead from 'next/head';

interface IProps {
  title?: string;
  description?: string;
  image?: string;
  url?: string;
}

const defaults = {
    title: 'Графит Арт — Студия Дизайна Интерьеров в Республике Беларусь и Российской Федерации, Минск, Москва, Санкт-Петербург, Бобруйск',
    description: 'Создаем уникальные дизайн-проекты для коммерческих и частных интерьеров в Республике Беларусь и Российской Федерации, Минске, Москве и Санкт-Петербурге, Бобруйске. Обращайтесь за профессиональной консультацией и воплотите свои идеи в жизнь с Графит Арт.',
    image: 'https://grafit-art.com/assets/main/4.webp',
    url: 'https://grafit-art.com/',
};

export const Meta = ({ title, description, image, url }: IProps) => {
    const metaTitle = title || defaults.title;
    const metaDescription = description || defaults.description;
    const metaImage = image || defaults.image;
    const metaUrl = url || defaults.url;

    return (
        <NextHead>
            <meta charSet="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=2" />
            <meta name="google-site-verification" content="gwt9VditV7sQ73Is8sGRqQgwqYViWC9F13r1g1tlSXw" />
            <meta name="robots" content="index, follow" />
            <title>{metaTitle}</title>
            <link rel="icon" href="/favicon.ico" />
            <meta name="description" key="desc" content={metaDescription} />
            <meta name="keywords" content="дизайн интерьера, студия дизайна, ремонт интерьера, дизайн коммерческих помещений, дизайн частных интерьеров, Минск, Москва, Санкт-Петербург, Графит Арт" />
            <meta name="author" content="Графит Арт" />
            <meta httpEquiv="Content-Language" content="ru" />

            {/* Open Graph Tags */}
            <meta property="og:title" content={metaTitle} />
            <meta property="og:description" content={metaDescription} />
            <meta property="og:image" content={metaImage} />
            <meta property="og:url" content={metaUrl} />
            <meta property="og:type" content="website" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:site_name" content="Grafit" />
            <meta property="og:image:width" content="467" />
            <meta property="og:image:height" content="263" />

            {/* Twitter Meta Tags */}
            <meta name="twitter:title" key="ttitle" content={metaTitle} />
            <meta name="twitter:description" key="tdesc" content={metaDescription} />
            <meta name="twitter:image" key="timg" content={metaImage} />
            <meta name="twitter:card" key="tcard" content="summary_large_image" />
            <meta name="twitter:site" content={metaUrl} />
            <meta name="twitter:creator" content={metaUrl} />
        </NextHead>
    );
};