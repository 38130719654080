import React, {ReactNode} from 'react';
import {AppProps} from 'next/app';
import Head from "next/head";
import {Roboto} from '@next/font/google';
import {createTheme, NextUIProvider} from '@nextui-org/react';

import ErrorBoundary from './ErrorBoundary';

// eslint-disable-next-line new-cap
const roboto = Roboto({
    subsets: ['latin'],
    weight: '400'
});

const lightTheme = createTheme({type: 'light'});

export default function MyApp({Component, pageProps}: AppProps): ReactNode {
    return (
        <>
            <Head>
                <link rel="shortcut icon" href="/favicon.ico" />
            </Head>
            <main className={roboto.className}>
                <ErrorBoundary>
                    <NextUIProvider theme={lightTheme}>
                        <Component {...pageProps} />
                    </NextUIProvider>
                </ErrorBoundary>
            </main>
        </>
        
    );
}
