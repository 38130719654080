import {Component, ReactNode} from 'react';
import React from 'react';

import styles from '../../components/ErrorBoundaryStyle/index.module.scss';
import {Meta} from '../../components/Meta';
interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error: null | Error;
}

class ErrorBoundary extends Component<Props, State> {
    constructor(props: Props | Readonly<Props>) {
        super(props);
        this.state = {
            hasError: false,
            error: null
        };
    }

    componentDidCatch(error: Error) {
        this.setState({
            hasError: true,
            error
        });
    }

    showText = () => {
        if (
            this.state.error &&
      this.state.error.toString() !== 'Error' &&
      this.state.error !== null
        ) {
            return this.state.error.toString();
        }
        return 'Something went wrong';
    };

    render() {
        if (this.state.hasError) {
            return (
                <>
                    <Meta />
                    <section className={styles.page_404}>
                        <div className={styles.four_zero_four_bg}></div>
                        <div className={styles.contant_box_404}>
                            <h3>Возникла небольшая ошибка</h3>
                            <p>попробуйте перезагрузить страницу</p>
                            <a href="" className={styles.link_404}>
                Перезагрузить
                            </a>
                        </div>
                    </section>
                </>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
